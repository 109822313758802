//@ts-nocheck
import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter
} from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';


//* Routes List start *//

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
const Backend = isMobile ? TouchBackend : HTML5Backend;

const ForgotPasswordScreen = lazy(() =>
  import('../../blocks/forgot-password/src/ForgotPassword.web'));
const ResetPasswordScreen = lazy(() =>
  import('../../blocks/forgot-password/src/ResetPassword.web'));
const VerificationCodepageScreen = lazy(() =>
  import('../../blocks/forgot-password/src/VerificationCodepage.web'));
const Settings5Screen = lazy(() =>
  import('../../blocks/Settings5/src/SettingPage.web'));
const BulkUploadingScreen = lazy(() =>
  import('../../blocks/bulkuploading/src/BulkUploading.web'));
const LoginScreen = lazy(() =>
  import('../../blocks/email-account-login/src/LoginScreen.web'));
const EditHomeDetailsForm = lazy(() =>
  import('../../blocks/bulkuploading/src/EditHomeDetailsForm.web'));
const SettingScreen = lazy(() =>
  import('../../blocks/Settings5/src/SettingScreen.web'));
const AdminNavigationScreen = lazy(() =>
  import('../../blocks/dashboard/src/AdminNavigationScreen.web'));
const AvailableWorkFlowScreen = lazy(() =>
  import('../../blocks/cfcustomworkflowtemplates/src/AvailableWorkFlow.web'));
const MainContainerWorkFlow = lazy(() =>
  import('../../blocks/cfcustomworkflowtemplates/src/MainContainerWorkFlow.web'));

const AccountScorerankingScreen = lazy(() =>
  import('../../blocks/AccountScoreranking/src/AccountScoreranking.web'));
const WorkflowScoreScreen = lazy(() =>
  import('../../blocks/AccountScoreranking/src/WorkflowScore.web'));
import('../../blocks/dashboard/src/AdminNavigationScreen.web');
const AddHomeBulk = lazy(() =>
  import('../../blocks/bulkuploading/src/BulkUploading.web')
);
const AddAssetBulk = lazy(() =>
  import('../../blocks/bulkuploading/src/AssetsBulkUploading.web')
);
const AddUserBulk = lazy(() =>
  import('../../blocks/bulkuploading/src/UsersBulkUploading.web')
);
const WorkflowMasterLayout = lazy(() =>
  import('../../blocks/multipageforms2/src/WorkflowMasterLayout')
);

const ViewResponses = lazy(() =>
  import('../../blocks/multipageforms2/src/ViewResponses')
);


const AssetFilters = lazy(() =>
  import('../../blocks/filteritems/src/AssetFilters.web')
);

const AddAsset = lazy(() => import('../../blocks/dashboard/src/AddAsset.web'));

const AccountAccess = lazy(() =>
  import('../../blocks/accountgroups/src/AccountAccess.web')
);
const AddNewUserForm = lazy(() =>
  import('../../blocks/accountgroups/src/AddNewUserForm.web')
);
const ShowCompanyTree = lazy(() =>
  import('../../blocks/accountgroups/src/ShowCompanyTree.web')
);
const AddAssetDialog = lazy(() =>
  import('../../blocks/dashboard/src/AddAssetDialog.web')
);
const FilterItems = lazy(() =>
  import('../../blocks/filteritems/src/MyProperty.web')
);

const BasicTabs = lazy(() =>
  import('../../blocks/DashboardGuests/src/tabs.web'));
const Multipageforms2 = lazy(() =>
  import('../../blocks/multipageforms2/src/Multipageforms.web')
);
const AssetDetailsScreen = lazy(() =>
  import("../../blocks/multipageforms2/src/AssetDetailsScreen.web"));
const EditAsset = lazy(() =>
  import("../../blocks/multipageforms2/src/EditAsset.web"));

const AssetManagement = lazy(() => import('../../blocks/multipageforms2/src/AssetManagement.web'))

const DashboardTags = lazy(() =>
  import('../../blocks/cftag/src/DashboardTag.web')
);

const DashboardTagsProperties = lazy(() =>
  import('../../blocks/cftag/src/DashboardTagProperties.web')
);

const DashboardTagsHome = lazy(() =>
  import('../../blocks/cftag/src/DashboardTagHome.web')
);
const DashboardTagsOwner = lazy(() =>
  import('../../blocks/cftag/src/DashboardTagOwner.web')
);

const DashboardTagsAssets = lazy(() =>
  import('../../blocks/cftag/src/DashboardTagAssets.web')
);
const ShowUser = lazy(() =>
  import('../../blocks/accountgroups/src/ShowUser.web')
);
const EditUser = lazy(() =>
  import('../../blocks/accountgroups/src/EditUser.web')
);
const Report = lazy(() =>
  import('../../blocks/visualanalytics/src/ReportHome.web')
)
const AssetReport = lazy(() =>
  import('../../blocks/visualanalytics/src/AssetConditionReport.web')
)
const CarePlanReport = lazy(() =>
  import('../../blocks/visualanalytics/src/CarePlanReport.web')
)
const HomeReport = lazy(() =>
  import('../../blocks/visualanalytics/src/HomeReport.web')
)
const CustomReport = lazy(() => 
  import('../../blocks/visualanalytics/src/CustomReport.web')
)
const EditHome = lazy(() =>
  import('../../blocks/multipageforms2/src/EditHome.web')
);

const Workflow = lazy(() =>
  import('../../blocks/multipageforms2/src/WorkflowLandingPage.web')
);
const DashboardTagsView = lazy(() =>
  import('../../blocks/cftag/src/DashboardTagView.web')
);
const TagsView = lazy(() =>
  import('../../blocks/DashboardGuests/src/Tags.web')
)

//* Routes List End *//

//* Private Routes List start *//
import PrivateRoute from './PrivateRoute.web';
import Loader from '../../components/src/Loader.web';
//* Private Routes List end *//

function WebRoutes(rootProps: any) {
  return (
    <Router>
      <Suspense fallback={<Loader loading />}>
        <Switch>
          <PrivateRoute path="/myproperties">
            <AdminNavigationScreen>
              <Route
                path="/myproperties/homes"
                exact
                render={props => <FilterItems {...props} key={props.location.search} />}
              />
              <Route
                path="/myproperties/homes/:home_id/edit"
                exact
                render={props => <EditHome {...props} />}
              />
            </AdminNavigationScreen>
          </PrivateRoute>
          <PrivateRoute
            path="/"
            exact
            render={props => <AdminNavigationScreen {...props} />}
          />
          <Route
            path="/forgotpassword"
            exact
            render={props => <ForgotPasswordScreen {...props} />}
          />
          <Route
            path="/resetpassword"
            exact
            render={props => <ResetPasswordScreen {...props} />}
          />
          <Route
            path="/verificationcode"
            exact
            render={props => <VerificationCodepageScreen {...props} />}
          />
          <Route
            path="/settings"
            exact
            render={props => <Settings5Screen {...props} />}
          />
          <Route
            path="/BulkUploadingScreen"
            exact
            render={props => <BulkUploadingScreen {...props} />}
          />
          <Route
            path="/login"
            exact
            render={props => <LoginScreen {...props} />}
          />
          <PrivateRoute path="/entity">
            <AdminNavigationScreen>

              <Route
                path='/entity/add-new-asset'
                exact
                render={props => <AddAsset {...props} />}></Route>
              <Route
                path='/entity/add-new-asset/:home_id/:asset_id'
                exact
                render={props => <AddAsset {...props} key={props.match.params.asset_id} />}></Route>
              <Route
                path='/entity/add-new-asset/:home_id'
                exact
                render={props => <AddAsset {...props} key={props.match.params.asset_id} />}></Route>
              <Route
                path='/entity/add-asset-button'
                exact
                render={props => <AddAssetDialog {...props} />}></Route>
              <Route
                path="/entity/home-details/:home_id"
                exact
                render={props => <AssetFilters {...props} key={props.match.params.home_id} />}
              />
              <Route
                path="/entity/asset-details/:asset_id"
                exact
                render={props => <AssetDetailsScreen {...props} key={props.match.params.asset_id} />}
              />
              <Route
                path="/entity/edit-asset/:asset_id"
                exact
                render={props => <EditAsset {...props} key={props.match.params.asset_id} />}
              />
            </AdminNavigationScreen>
          </PrivateRoute>
          <PrivateRoute path="/admin">
            <AdminNavigationScreen>
              <Route
                path="/admin/customworkflow"
                exact
                render={props => <MainContainerWorkFlow  {...props} />}
              />

              <Route
                path="/admin/workflowLandingPage"
                exact
                render={props => <Workflow {...props} />}
              />
              <Route
                path='/admin/workflow/:workflow_id'
                exact
                render={props => <WorkflowMasterLayout {...props} />}></Route>
              <Route
                path='/admin/workflow/response/:workflow_score_id'
                exact
                render={props => <ViewResponses {...props} />}></Route>

              <Route
                path='/admin/dashboard'
                exact
                render={props => <BasicTabs {...props} />}></Route>
              <Route
                path='/admin/myproperties'
                exact
                render={props => <SettingScreen {...props} />}></Route>
              <Route
                path='/admin/assets'
                exact
                render={props => <AssetManagement {...props} />}></Route>

              <Route
                path='/admin/account&access'
                exact
                render={props => <AccountAccess {...props} />}></Route>
              <Route
                path='/admin/userform'
                exact
                render={props => <AddNewUserForm {...props} />}></Route>
              <Route
                path='/admin/report'
                exact
                render={props => <Report {...props} />}></Route>
              <Route
                path='/admin/report/assetcondition'
                exact
                render={props => <AssetReport {...props} />}></Route>
              <Route
                path='/admin/report/careplanreport'
                exact
                render={props => <CarePlanReport {...props} />}></Route>
              <Route
                path='/admin/report/homereport'
                exact
                render={props => <HomeReport {...props} />}></Route>
              <Route
                path='/admin/report/customreport'
                exact
                render={props => <CustomReport {...props} />}></Route>
              <Route
                path='/admin/project'
                exact
                render={props => <SettingScreen {...props} />}></Route>
              <Route
                path='/admin/analytics'
                exact
                render={props => <SettingScreen {...props} />}></Route>
              <Route
                path='/admin/setting'
                exact
                render={props => <SettingScreen {...props} />}></Route>
              <Route
                path='/admin/Multipageforms2'
                exact
                render={props => <Multipageforms2 {...props} />} />
              <Route
                path='/admin/userbulk'
                exact
                render={props => <AddUserBulk {...props} />} />
              <Route
                path='/admin/userinfo/:id'
                exact
                render={props => <ShowUser {...props} key={props.match.params.id} />} />
              <Route
                path='/admin/companyinfo/:id'
                exact
                render={props => <ShowCompanyTree {...props} key={props.match.params.id} />}/>
              <Route
                path='/admin/editinfo/:id'
                exact
                render={props => <EditUser {...props} />} />
            </AdminNavigationScreen>
          </PrivateRoute>
          <PrivateRoute path="/superadmin">
            <AdminNavigationScreen>
              <Route
                path='/superadmin/workflow/:workflow_id'
                exact
                render={props => <WorkflowMasterLayout {...props} />}></Route>
              <Route
                path='/superadmin/workflow/response/:workflow_score_id'
                exact
                render={props => <ViewResponses {...props} />}></Route>
              <Route
                path='/superadmin/dashboard'
                exact
                render={props => <BasicTabs {...props} />}></Route>
              <Route
                path='/superadmin/myproperties'
                exact
                render={props => <SettingScreen {...props} />}></Route>
              <Route
                path="/superadmin/workflowLandingPage"
                exact
                render={props => <Workflow {...props} />}
              />
              <Route
                path='/superadmin/assets'
                exact
                render={props => <AssetManagement {...props} />}></Route>
              <Route
                path='/superadmin/companyinfo/:id'
                exact
                render={props => <ShowCompanyTree {...props} key={props.match.params.id} />}/>



              <Route
                path='/superadmin/workflows/edit/:workflow_id'
                exact
                render={props => <AvailableWorkFlowScreen {...props} create key={Math.random()} />} />

              <Route
                path='/superadmin/tags'
                exact
                render={props => <TagsView {...props} />}></Route>

              <Route
                path='/superadmin/workflows/createworkflows/:create_workflow_id'
                exact
                render={props => <DndProvider backend={Backend} options={{ enableMouseEvents: true }}>  <MainContainerWorkFlow {...props} />   </DndProvider>}></Route>


              <Route
                path='/superadmin/workflows/create'
                exact
                render={props => <AvailableWorkFlowScreen {...props} create key={Math.random()} />}></Route>
              <Route
                path='/superadmin/workflows'
                exact
                render={props => <AvailableWorkFlowScreen {...props} key='index' />}></Route>


              <Route
                path='/superadmin/workflows/createworkflows'
                exact
                render={props => <DndProvider backend={Backend} options={{ enableMouseEvents: true }} >  <MainContainerWorkFlow {...props} />   </DndProvider>}></Route>


              <Route
                path='/superadmin/workflows/editworkflows'
                exact
                render={props => <AccountScorerankingScreen {...props} />}></Route>
              <Route
                path='/superadmin/workflows/worflowsscrore'
                exact
                render={props => <WorkflowScoreScreen {...props} />}></Route>
              <Route
                path='/superadmin/account&access'
                exact
                render={props => <AccountAccess {...props} />}></Route>
              <Route
                path='/superadmin/report'
                exact
                render={props => <Report {...props} />}></Route>
              <Route
                path='/superadmin/report/assetcondition'
                exact
                render={props => <AssetReport {...props} />}></Route>
              <Route
                path='/superadmin/report/careplanreport'
                exact
                render={props => <CarePlanReport {...props} />}></Route>
              <Route
                path='/superadmin/report/homereport'
                exact
                render={props => <HomeReport {...props} />}></Route>
              <Route
                path='/superadmin/report/customreport'
                exact
                render={props => <CustomReport {...props} />}></Route>
              <Route
                path='/superadmin/project'
                exact
                render={props => <SettingScrter-domeen {...props} />}></Route>
              <Route
                path='/superadmin/analytics'
                exact
                render={props => <SettingScreen {...props} />}></Route>
              <Route
                path='/superadmin/setting'
                exact
                render={props => <SettingScreen {...props} />}></Route>
              <Route
                path='/superadmin/Multipageforms2'
                exact
                render={props => <Multipageforms2 {...props} />} />
              <Route
                path='/superadmin/userinfo/:id'
                exact
                render={props => <ShowUser {...props} />} />
              <Route
                path='/superadmin/editinfo/:id'
                exact
                render={props => <EditUser {...props} />} />
            </AdminNavigationScreen>
          </PrivateRoute>
          <PrivateRoute path="/owner">
           <AdminNavigationScreen>
          <Route
                path='/owner/dashboard'
                exact
                render={props => <BasicTabs {...props} />}></Route>
                <Route
                path="/owner/homes"
                exact
                render={props => <FilterItems {...props} key={props.location.search} />}></Route>
                <Route
                path="/owner/workflowLandingPage"
                exact
                render={props => <Workflow {...props} />}
              />
              <Route
                path='/owner/workflow/:workflow_id'
                exact
                render={props => <WorkflowMasterLayout {...props} />}></Route>
              <Route
                path='/owner/workflow/response/:workflow_score_id'
                exact
                render={props => <ViewResponses {...props} />}></Route>
              <Route
                path='/owner/asset'
                exact
                render={props => <AssetManagement {...props} />}></Route>
                 <Route
                path='/owner/setting'
                exact
                render={props => <SettingScreen {...props} />}></Route>
        </AdminNavigationScreen>
          </PrivateRoute>
          <PrivateRoute path="/technician">
            <AdminNavigationScreen>
              <Route
                path='/technician/dashboard'
                exact
                render={props => <BasicTabs {...props} />}></Route>
              <Route
                path='/technician/myproperties'
                exact
                render={props => <SettingScreen {...props} />}></Route>
              <Route
                path="/technician/workflowLandingPage"
                exact
                render={props => <Workflow {...props} />}
              />
              <Route
                path='/technician/workflow/:workflow_id'
                exact
                render={props => <WorkflowMasterLayout {...props} />}></Route>
              <Route
                path='/technician/workflow/response/:workflow_score_id'
                exact
                render={props => <ViewResponses {...props} />}></Route>
              <Route
                path='/technician/asset'
                exact
                render={props => <AssetManagement {...props} />}></Route>
              <Route
                path='/technician/project'
                exact
                render={props => <SettingScreen {...props} />}></Route>
              <Route
                path='/technician/setting'
                exact
                render={props => <SettingScreen {...props} />}></Route>
              <Route
                path='/technician/Multipageforms2'
                exact
                render={props => <Multipageforms2 {...props} />} />
            </AdminNavigationScreen>
          </PrivateRoute>
          <PrivateRoute path="/tenant">
            <AdminNavigationScreen>
            <Route
                path='/entity/home-details/:home_id'
                exact
                render={props => <AssetFilters {...props} key={props.match.params.home_id} />}></Route>
             <Route
                path="/tenant/workflowLandingPage"
                exact
                render={props => <Workflow {...props} />}
              />
              <Route
                path='/tenant/workflow/:workflow_id'
                exact
                render={props => <WorkflowMasterLayout {...props} />}></Route>
              <Route
                path='/tenant/workflow/response/:workflow_score_id'
                exact
                render={props => <ViewResponses {...props} />}></Route>
            <Route
                path='/tenant/tipsandrecommendations'
                exact
                render={props => <SettingScreen {...props} />}></Route>
              <Route
                path='/tenant/setting'
                exact
                render={props => <SettingScreen {...props} />}></Route>
            </AdminNavigationScreen>
          </PrivateRoute>
          <Route
            path="/edithomedetailsform"
            exact
            render={props => <EditHomeDetailsForm {...props} />}
          />


          <Route
            path="/EditWorkflow"
            exact
            render={props => <AccountScorerankingScreen {...props} />}
          />
          <Route
            path="/Workflowscore"
            exact
            render={props => <WorkflowScoreScreen {...props} />}
          />
          <Route
            path="/homebulk"
            exact
            render={props => <AddHomeBulk {...props} />}
          />
          <Route
            path="/assetsbulk"
            exact
            render={props => <AddAssetBulk {...props} />}
          />
          <Route
            path="/usersbulk"
            exact
            render={props => <AddUserBulk {...props} />}
          />
          <Route
            path="/myproperties"
            exact
            render={props => <FilterItems {...props} key={props.location.search} />}
          />

          <Route
            path="/userform"
            exact
            render={props => <AddNewUserForm {...props} />}
          />
          <Route
            path="/accountaccess"
            exact
            render={props => <AccountAccess {...props} />}
          />


          <PrivateRoute path="/dashboard">
            <AdminNavigationScreen>
              <Route
                path="/dashboard/commondashboard"
                exact
                render={props => <BasicTabs {...props} />}
              />
              <Route
                path="/dashboard/tags/view/:tag_id"
                exact
                render={props => < DashboardTagsView {...props} key={props.match.params.tag_id} />}
              />
              <Route
                path="/dashboard/tags"
                exact
                render={props => <DashboardTags {...props} />}
              />
            </AdminNavigationScreen>
          </PrivateRoute>

          <PrivateRoute path="/tags">
            <AdminNavigationScreen>
      
              <Route
                path="/tags/tagsProperties"
                exact
                render={props => <DashboardTagsProperties {...props} />}
              />
              <Route
                path="/tags/tagsHomes"
                exact
                render={props => <DashboardTagsHome {...props} />}
              />

              <Route
                path="/tags/tagsOwners"
                exact
                render={props => <DashboardTagsOwner {...props} />}
              />
              <Route
                path="/tags/tagsAssets"
                exact
                render={props => <DashboardTagsAssets {...props} />}
              />
              <Route
                path="/tags/view/:tag_id"
                exact
                render={props => < DashboardTagsView {...props} key={props.match.params.tag_id} />}
              />
            </AdminNavigationScreen>
          </PrivateRoute>

          <PrivateRoute path="/dashboard">
            <AdminNavigationScreen>
              <Route
                path="/dashboard/commondashboard"
                exact
                render={props => <BasicTabs {...props} />}
              />
              <Route
                path="/dashboard/tags"
                exact
                render={props => <DashboardTags {...props} />}
              />
            </AdminNavigationScreen>
          </PrivateRoute>
        </Switch>
      </Suspense>
    </Router>
  );
}

export default withRouter(WebRoutes);
